import React, { Fragment } from "react";
import { Link } from "gatsby";
import { FaGithub, FaTwitter, FaLinkedin, FaLink, FaCoffee } from "react-icons/fa";
import { rhythm, scale } from "../utils/typography";

class Layout extends React.Component {
  renderBanner(tags) {
    if (false) {
      return (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "#2d2d2d",
            width: "100vw",
            textAlign: "center",
            padding: "5px 5px"
          }}
        >
          <span style={{ fontSize: "18px", color: "white" }}>
            Want to stay up-to-date with React Native? Check out{" "}
            <a style={{ fontWeight: "bold" }} href="https://reactnativenow.com">
              React Native Now!
            </a>
          </span>
        </div>
      );
    }
  }

  render() {
    const { location, title, tags, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      );
    } else {
      header = (
        <h3
          style={{
            // fontFamily: `Montserrat, sans-serif`,
            marginTop: 0
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      );
    }
    return (
      <Fragment>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
          }}
        >
          <header>{header}</header>
          <main>{children}</main>
          <footer
            style={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <a href="https://ko-fi.com/lefkowitz">
              <FaCoffee size="1.4em" />
            </a>
            <div style={{ margin: "0 5px" }} />
            <a href="https://www.linkedin.com/in/lefkowitzmichael">
              <FaLinkedin size="1.4em" />
            </a>
            <div style={{ margin: "0 5px" }} />
            <a href="https://www.twitter.com/lfkwtz">
              <FaTwitter size="1.4em" />
            </a>
            <div style={{ margin: "0 5px" }} />
            <a href="https://www.github.com/lfkwtz">
              <FaGithub size="1.4em" />
            </a>
          </footer>
        </div>
        {this.renderBanner(tags)}
      </Fragment>
    );
  }
}

export default Layout;
